import authHeader from './auth-header'

const API_BASE = process.env.VUE_APP_API_URL

class DecreesService {
    async getAllDecrees() {
        let response = await fetch(`${API_BASE}/Arrete`, {
        method: 'GET',
          headers: authHeader()
        })
  
        if(response && response.ok ) {
          try{
            let decrees = await response.json()
            return decrees
          }catch{
            return null
          }
        }else{
          return null
        }
    }

    async getLatestDecrees() {
        let response = await fetch(`${API_BASE}/Arrete/latest`, {
        method: 'GET',
          headers: authHeader()
        })

        if(response && response.ok ) {
          try{
            let decrees = await response.json()
            return decrees
          }catch{
            return null
          }
        }else {
          return null
        }
    }

    async getDecreesByZoneId(id) {
        let response = await fetch(`${API_BASE}/Arrete/${id}`, {
        method: 'GET',
          headers: authHeader()
        })
  
        if(response && response.ok && response.status !== 204) {
          let decrees = await response.json()
          return decrees
        } else if (response.status === 204){
          return null
        } else {
            throw new Error(response.statusText)
        }
    }

}

export default new DecreesService()